import React from 'react';
import { connect } from 'react-redux'

import LoginCredentialElement from './LoginCredentialElement'
import LoginMfaElement from './LoginMfaElement'
import LoginASElement from './LoginASElement'
import ErrorMessage from './ErrorMessage'
import { institutionLogin, processMFA, submitSelectedAccounts } from '../actions' 
import { institutionSelected } from '../actions'
import {loginStates} from '../constants/states'
import DateOfBirthFields from './DateOfBirthFields';



class CurrentInstitutionLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userInputValues : this.genDefaultUserInputValues(this.props.institution.credentials),
            showNoAccountsSelectedSign: false,
            selectedAccount: this.selectAllAccounts(),
            showMergedEstatementPrivacyNote: this.showMergedEstatementPrivacyNote(true),
        }; //Local stuff, no need to propagate to global store. Will be sent on submit only
        if (this.props.institution.hide_merged_estatement_privacy_note === "0"
        && this.props.institution.estatements_supported === "1"
        && this.props.sessionData.clientSettings.requiresEstatements) {
            this.mergedEstatementPrivacyNoteNeeded = true;
        } else {
            this.mergedEstatementPrivacyNoteNeeded = false;
        }
        this.mfa = {};
    }

    selectAllAccounts = () => {
        let selected = [];

        if(!this.props.sessionData.clientSettings.requestAllAccounts) {
            return selected;
        }
        if(this.props.currentLogin.state !== loginStates.LOGIN_STATE_ACCOUNTS_SELECTION) {
            return selected;
        }

        this.props.currentLogin.accounts.map((account, index) => selected[index] = true)

        return selected;
    }

    showMergedEstatementPrivacyNote = (initial, selected) => {
        let result = true

        if(initial === true) {
            if(this.props.sessionData.clientSettings.requestAllAccounts === true) {
                return false
            } else {
                return true
            }
        }

        if(this.props.currentLogin.state !== loginStates.LOGIN_STATE_ACCOUNTS_SELECTION) {
            return result
        }

        if(this.props.currentLogin.accounts.length === selected.filter(Boolean).length) {
            result = false
        } else {
            result = true
        }

        this.setState({showMergedEstatementPrivacyNote: result})
    }

    normalizedSelectedAccounts = () => this.state.selectedAccount.map( (el,i) => el === true ? i : false).filter( el => el !== false )

    genDefaultUserInputValues = credentialElements => {

        let userInputValues = {}

        credentialElements.forEach(credentialElement => {
            
            switch (credentialElement.type.toLowerCase()) {
                case "text":                      
                case "password":                    
                case "captcha":
                    userInputValues[credentialElement.fieldID] = "";
                    break;
                case "select":
                    let optionValues = credentialElement.values;
                    let keys = Object.keys(optionValues);
                    userInputValues[credentialElement.fieldID] = keys[0];
                    break;
                case "pinpad":
                    userInputValues[credentialElement.fieldID] = "";
                    break;
                default:
                    break;
            }
        });

        return userInputValues 
    }

    onInputFieldChange = fieldID => event => {
        const { value } = event.target;
        this.setState({userInputValues: {...this.state.userInputValues, [fieldID]: value}});
    }

    onPinPadImgClick = fieldID => event => {

        const pinpadId = event.target.getAttribute('data-field-id');
        const pinpadType = event.target.getAttribute('data-field-type');

        let pinpadInputs;
        // get value of DOB field
        pinpadInputs = this.state.userInputValues["dob"];

        let pinpadInputsObj;
        pinpadInputsObj = [];
        // check if it is empty or not. if not then split it
        if(pinpadInputs !== ""){
            pinpadInputsObj = pinpadInputs.split(',');
        }

        if(pinpadType === 'number'){
            // if dob length reached 8 then no need to change
            if(pinpadInputsObj.length === 8) { return; }
            // concat new value with old one
            if(pinpadInputs !== ""){
                pinpadInputs = pinpadInputs + ',' + pinpadId;
            } else {
                pinpadInputs = pinpadId;
            }
        } else {
            pinpadInputsObj.pop();
            pinpadInputs = pinpadInputsObj.join(',');
        }

        if(pinpadInputs === ''){
            pinpadInputsObj = [];
        } else {
            pinpadInputsObj = pinpadInputs.split(',');
        }

        this.update_pinpad_viewer(pinpadInputsObj.length);

        let updatedUserInputs = this.state.userInputValues;
        updatedUserInputs['dob'] = pinpadInputs;
        this.setState({userInputValues: updatedUserInputs});
    }

    update_pinpad_viewer = (length) => {

        if(length > 8) { return; }

        var pinpadInputVal = '';
        if(length === 1){
            pinpadInputVal = 'D'
        } else if(length === 2){
            pinpadInputVal = 'DD'
        } else if(length === 3){
            pinpadInputVal = 'DD M'
        } else if(length === 4){
            pinpadInputVal = 'DD MM'
        } else if(length === 5){
            pinpadInputVal = 'DD MM Y'
        } else if(length === 6){
            pinpadInputVal = 'DD MM YY'
        } else if(length === 7){
            pinpadInputVal = 'DD MM YYY'
        } else if(length === 8){
            pinpadInputVal = 'DD MM YYYY'
        }
        document.getElementById("dob_viewer").value = pinpadInputVal;
    }

    onCancel = () => {
        this.setState({userInputValues: {}});
        this.props.dispatch({type: 'LOGIN_PROCESS_CANCELLED'});   
    }

    onRetry = () => {        
        this.props.dispatch(institutionSelected(this.props.currentLogin.slug, this.props.institution.requires_preload))
    }

    onSubmit = (event) => {
        event.preventDefault()        
        this.props.dispatch(institutionLogin(this.props.institution, this.state.userInputValues))
    }

    mfaOnSubmit = event => { 
        this.props.dispatch(processMFA(this.props.institution, this.mfa))
    }

    mfaOnChange = (key, value) => {
        this.mfa[key] = value;
    }

    genMFAFields = () => {

        let needsSubmitButton = true;
        let fields = this.props.currentLogin.mfa.fields.map((mfaElement, index) => <LoginMfaElement key={index} isSet={false} data={mfaElement} onChange={this.mfaOnChange} onSubmit={this.mfaOnSubmit}/>)

        this.props.currentLogin.mfa.fields.forEach(e => {
            if (e.type.toLowerCase() === 'options') {
                needsSubmitButton = false;
            }
        });

        if (needsSubmitButton) {
            fields.push (
                <div className="form-group" key="submitBtn">
                    <button className="form-control btn btn-primary" onClick={this.mfaOnSubmit}>{this.props.theme.text.mfaSubmitBtn}</button>
                </div>
            );
        }

        // Persist the value for hidden input fields
        // These would otherwise never be sent back to the server
        this.props.currentLogin.mfa.fields
        .filter(field => field.type === 'hidden' && field.id && !field.value && field.htmlAttrs && field.htmlAttrs.value)
        .forEach(field => {
            this.mfaOnChange(field.id, field.htmlAttrs.value)
        });
        
        return fields;
    }

    asOnChange = event => {
        let selected = this.props.sessionData.clientSettings.forceSingleAccount ? [] : [...this.state.selectedAccount]; //copy array
        selected[event.target.value] = event.target.checked
        this.setState({selectedAccount: selected})
        this.showMergedEstatementPrivacyNote(false, selected)
    }

    asOnSubmit = event => {
        event.preventDefault()

        let normalizedSelectedAccounts = this.normalizedSelectedAccounts()

        if (normalizedSelectedAccounts.length > 0)  {
            this.setState({showNoAccountsSelectedSign: false})            
            const password = this.props.institution.export_with_password === "1" ? this.state.userInputValues["password"] : "";
            this.props.dispatch(submitSelectedAccounts(this.props.institution, normalizedSelectedAccounts, password))

        } else {
            this.setState({showNoAccountsSelectedSign: true})
        }
    }

    render() {
        let winzAddtionalDescription='';
        let backToSelectionBtnText=this.props.theme.text.backToSelectionBtn;
        let requireWinz=false;
        let forceAllAccountsWarningText = this.props.theme.text.ForceAllAccountsWarning !== undefined ? this.props.theme.text.ForceAllAccountsWarning : "Please note that once you submit your details, statements for all bank accounts will be automatically retrieved and sent to ";
        if(this.props.institution.slug==='winz'){
            requireWinz=true;
            backToSelectionBtnText='Submit bank statements without '+this.props.institution.name;
            winzAddtionalDescription= 'Please enter your MyMSD login details below to submit your '+this.props.institution.name+' statements:';
        }
        const classSelector = "selector_" + this.props.currentLogin.state + "_form";
        var statusMessage='';
        if(this.props.institution.present==="0"){
            statusMessage="We are currently experiencing issues in retrieving data from this institution. If you experience any problems, please try again later.";
            if (this.props.institution.is_override_status_message==="1"&&this.props.institution.status.trim()!==''){
                statusMessage=this.props.institution.status;
            }
        }else if(this.props.institution.present === "1" && this.props.institution.is_override_status_message==="1" && this.props.institution.status.trim()!==''){
            statusMessage=this.props.institution.status;
        }

        switch (this.props.currentLogin.state) {

            case loginStates.LOGIN_STATE_CREDENTIAL_FORM:
                const dobFields = this.props.institution.credentials.filter(
                    credentialElement => credentialElement.fieldID === "day" || credentialElement.fieldID === "month" || credentialElement.fieldID === "year"
                );               
                return <form onSubmit={this.onSubmit} className={classSelector}>                      
                        <div className="form-group">
                            <h2>{this.props.institution.name}</h2>
                            {this.props.institution.slug==='bnz'?<h6>Please use the Username and Password that you use to log in to your internet banking (not your Mobile Banking app)</h6>:''}

                            {statusMessage===''?<div/>:<div className="error-banner"><p>{statusMessage}</p></div>}

                            {this.props.theme.text.BankNameSubtitle !== undefined? <p class="bank-name-subtitle">{this.props.theme.text.BankNameSubtitle}</p> : null}
                        </div>
                    {winzAddtionalDescription && <div className="form-group"><p>{winzAddtionalDescription}</p></div>}
                        {this.props.institution.credentials.map(credentialElement => <LoginCredentialElement 
                            data={credentialElement} 
                            key={credentialElement.fieldID} 
                            inputValue={this.state.userInputValues[credentialElement.fieldID]}
                            onChange={this.onInputFieldChange(credentialElement.fieldID)}
                            optional={credentialElement.hasOwnProperty('optional') ? credentialElement.optional : false}
                            onPinPadClick={this.onPinPadImgClick(credentialElement.fieldID)}
                        />)}
                        {requireWinz && dobFields.length > 0 && (<DateOfBirthFields dobFields={dobFields} onInputFieldChange={this.onInputFieldChange} />)}
                        {this.props.sessionData.clientSettings.forceAllAccounts && this.props.institution.slug !== 'winz' ? <ForceAllAccounts content={forceAllAccountsWarningText} client={this.props.sessionData.clientSettings.name}/> : ''}
                        <div className="form-group">
                            <button id="loginSubmitBtn" type="submit" className="form-control btn btn-primary">{this.props.theme.text.loginSubmitBtn}</button>
                        </div>
                        <CancelLogin action={this.onCancel} theme={this.props.theme} backToSelectionBtnText={backToSelectionBtnText} requireWinz={requireWinz}/>
                    </form>
            case loginStates.LOGIN_STATE_MFA_FORM:
                return <form className={classSelector}>
                        <div className="form-group">
                            <h2>{this.props.currentLogin.mfa.title}</h2>
                            <MFAInfoMessage theme={this.props.theme}/>
                        </div>                                    
                        {this.genMFAFields()}                                    
                        <div className="form-group">
                            <CancelLogin action={this.onCancel} theme={this.props.theme} backToSelectionBtnText={backToSelectionBtnText} requireWinz={requireWinz}/>
                        </div>                              
                    </form>
            case loginStates.LOGIN_STATE_ACCOUNTS_SELECTION:
                if(this.props.sessionData.clientSettings.forceNoAccounts) {
                    const password = this.props.institution.export_with_password === "1" ? this.state.userInputValues["password"] : "";
                    this.props.dispatch(submitSelectedAccounts(this.props.institution, [], password))
                    return null;
                }
                return <form onSubmit={this.asOnSubmit} className={classSelector}>
                        <div className="form-group">
                            <h2>{this.props.sessionData.clientSettings.forceSingleAccount? (this.props.theme.text.accountsSelectionInstructionSingleAccount?this.props.theme.text.accountsSelectionInstructionSingleAccount:'Please select the account you would like to retrieve bank statements from') : this.props.theme.text.accountsSelectionInstruction}</h2>
                        </div>
                        {this.props.currentLogin.accounts.map((account, index) => <LoginASElement key={index} index={index} data={account} forceSingleAccount={this.props.sessionData.clientSettings.forceSingleAccount} onChange={this.asOnChange} checked={!!this.state.selectedAccount[index]} />)}
                        {this.mergedEstatementPrivacyNoteNeeded
                            && this.state.showMergedEstatementPrivacyNote ?
                            <MergedEstatementPrivacyNote theme={this.props.theme} /> : null}
                        {this.state.showNoAccountsSelectedSign ? <div className="alert alert-warning" role="alert">You must select at least one account</div> : null}
                        {this.props.institution.export_with_password === "1" ? <div>{this.props.theme.text.exportWithPasswordTitle}</div> : null }
                        {this.props.institution.export_with_password === "1" ? <LoginCredentialElement 
                            data={{"name":"Password", "type":"password"}} 
                            key="password"                            
                            onChange={this.onInputFieldChange("password")}
                            optional={false}          
                        />: null}
                        <div key="submitBtn" className="form-group">
                            <button id="accountsSelectionSubmitBtn" className={this.props.theme.classes.accountsSelectionSubmitBtn} type="submit">{this.props.theme.text.accountsSelectionSubmitBtn}</button>
                        </div>                           
                        <div className="form-group">
                            <CancelLogin action={this.onCancel} theme={this.props.theme} backToSelectionBtnText={backToSelectionBtnText} requireWinz={requireWinz}/>
                        </div>
                    </form>                           
            case loginStates.LOGIN_STATE_ERROR:
                const hideButtons = (this.props.currentLogin.error.message === "Your session has ended due to timeout. Please refresh the page and try again. ");
                return <>
                        <ErrorMessage data={this.props.currentLogin.error}/>
                        {hideButtons ? null :
                            <div>
                                <CancelLogin action={this.onCancel} theme={this.props.theme} backToSelectionBtnText={backToSelectionBtnText} requireWinz={requireWinz}/>
                                <RetryLogin action={this.onRetry} theme={this.props.theme}/>
                            </div>
                        }
                    </>
            default:
                return null            
        }
    }

}

const CancelLogin = (props) => 
    <div className="form-group">
        <button className="form-control btn btn-secondary" onClick={props.action} type="button">{!props.requireWinz && <><span className="icon-chevron-circle-left"></span> &nbsp;</>}{props.backToSelectionBtnText}</button>
    </div>
const RetryLogin = (props) => 
    <div className="form-group">
        <button className="form-control btn btn-primary" onClick={props.action} type="button">{props.theme.text.retryLoginBtn}</button>
    </div>
const ForceAllAccounts = (props) =>
    <div className="alert alert-warning">
        {props.content} {props.client}.
    </div>
const MergedEstatementPrivacyNote = (props) =>
    <div className='linify merged-statement-note'>
        <strong style={{textDecoration: "underline"}}>
            {props.theme.text.accountSelectionMessageTitle !== undefined?props.theme.text.accountSelectionMessageTitle:"This institution may provide a single statement for multiple accounts."}
            </strong><br/>
            {props.theme.text.accountSelectionMessageContent !== undefined?props.theme.text.accountSelectionMessageContent:"As you have unselected account/s, please be aware that information relating to the unselected account/s may be visible on the statement to be supplied. Please only proceed if you agree to this."}
            <br/><br/>
    </div>
const MFAInfoMessage = (props) =>
    <p className='linify merged-statement-note'>
            {props.theme.text.MFAInfoMessage !== undefined?props.theme.text.MFAInfoMessage:""}
            <br/><br/>
    </p>

const mapStateToProps = (state) => {
    return {
        theme: state.common.theme,
        currentLogin: state.currentLogin,
        sessionData: state.sessionData,
        institution: state.institutions.byslug[state.currentLogin.slug]
    }
}

export default connect(mapStateToProps)(CurrentInstitutionLogin)
